html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

fieldset {
  border: none;
  padding: 0;
}

#root {
  min-height: 100vh;
}
